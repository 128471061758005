import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 lg:grid-cols-2 gap-16 xl:gap-28" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "mb-3" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "el-icon el-icon--upload" }
const _hoisted_9 = { class: "flex justify-end items-center border-t-2 2xl:space-x-5 space-x-3 2xl:py-5 py-3 mt-10" }
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createBlock($setup["Form"], {
    as: "el-form",
    "validation-schema": $setup.schema,
    "initial-values": $setup.data,
    onSubmit: $setup.onSubmit,
    "label-position": "top"
  }, {
    default: _withCtx(({ isSubmitting, setFieldValue }) => [
      _createElementVNode("div", {
        class: _normalizeClass(isSubmitting && 'isSubmitting-rounded')
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["Field"], { name: "Name" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Name*"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "CountryContinentId" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Country / Region"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, _mergeProps(field, {
                      "model-value": value,
                      class: "w-full",
                      placeholder: "Select Country",
                      filterable: "",
                      onInput: (value) => $setup.remoteMethod(value.target?.value),
                      onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.remoteMethod('')))
                    }), {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.Value,
                            label: item.Name,
                            value: item.Value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1040, ["model-value", "onInput"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "Latitude" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Latitude"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      class: "w-full number-left",
                      placeholder: ""
                    }, field, {
                      controls: false,
                      "model-value": value
                    }), null, 16, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "Longitude" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Longitude"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      class: "w-full number-left",
                      placeholder: "",
                      controls: false
                    }, field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "IsOnline" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_switch, _mergeProps({
                      class: "mr-3",
                      size: "large"
                    }, field, { "model-value": value }), null, 16, ["model-value"]),
                    value
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($setup.STATUS.CONNECTED), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($setup.STATUS.NOT_CONNECTED), 1))
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode($setup["Field"], { name: "ImageUrl" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "mb-0",
                  error: errorMessage,
                  label: "Upload Image"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_upload, {
                      class: "file-uploader group",
                      drag: "",
                      "show-file-list": false,
                      "on-success": (res, file) => $setup.handleUploadImageSuccess(res, file, field, setFieldValue),
                      "auto-upload": false,
                      ref: "uploadRef",
                      "on-change": (res, file) => $setup.onChangeFile(res,file,setFieldValue),
                      file: ""
                    }, {
                      default: _withCtx(() => [
                        value
                          ? (_openBlock(), _createElementBlock("img", {
                              key: 0,
                              src: `${value}`,
                              class: "el-icon el-icon--upload"
                            }, null, 8, _hoisted_6))
                          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createElementVNode("i", _hoisted_8, [
                                _createVNode($setup["BaseSvgIcon"], {
                                  class: "inline-block group-hover:text-indino-primary text-white w-6 h-6",
                                  name: "picture"
                                })
                              ]),
                              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "el-upload__text" }, "Drag & Drop your image here", -1))
                            ]))
                      ]),
                      _: 2
                    }, 1032, ["on-success", "on-change"]),
                    _createVNode(_component_el_input, _mergeProps({
                      class: "hidden",
                      hidden: ""
                    }, field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 2
            }, 1024)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[2] || (_cache[2] = _createElementVNode("button", {
            type: "reset",
            class: "btn btn--gray"
          }, "Cancel", -1)),
          _createElementVNode("button", {
            type: "submit",
            class: "btn btn--green-primary",
            disabled: isSubmitting
          }, _toDisplayString($setup.FacilityId ? "Update" : "Save"), 9, _hoisted_10)
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["validation-schema", "initial-values"]))
}