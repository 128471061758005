import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "container my-3 2xl:my-5 py-3 px-10 2xl:py-1 2xl:px-20" }
const _hoisted_5 = { class: "w-full 2xl:py-5 py-3 flex justify-between items-center mb-2" }
const _hoisted_6 = {
  class: "text-2xl 2xl:text-4xl font-bold",
  "data-aos": "fade-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: `/facilities-monitoring` }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Facility Management")
          ])),
          _: 1
        })
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        ($setup.FacilityId)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: `facilities-monitoring/${$setup.FacilityId}/edit`
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Edit Facility ")
              ])),
              _: 1
            }, 8, ["to"]))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: "create"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("Create New Facility")
              ])),
              _: 1
            }))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h1", _hoisted_6, _toDisplayString($setup.FacilityId ? "Edit Facility" : "Create New Facility"), 1)
      ]),
      _createVNode($setup["TenentDetailCRUD"], { data: $setup.initialValues }, null, 8, ["data"])
    ])
  ]))
}