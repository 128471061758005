import { defineComponent as _defineComponent } from 'vue'
import TenantModule from "@ems/containers/Tenant/TenantUser.module";
import TenantFacilityModule from "@ems/containers/Tenant/TenantFacility.module";
import TenentDetailCRUD from "@ems/containers/Tenant/TheForm/TenentDetailCRUD.Form.vue";
import MetadataModule from "@/store/modules/Metadata";
import { computed, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'CRUDFacility',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const { facility_id: FacilityId }: { facility_id?: string } = route.params;

const initialValues = computed(
  () => TenantFacilityModule.detailTenantFacilityGetter
);

onMounted(() => {
  MetadataModule.getAllCountryAction();
  if (FacilityId) {
    TenantFacilityModule.getFacility({ FacilityId } as any);
  }
});

onUnmounted(() => {
  TenantFacilityModule.resetDetailTenantFacility();
});

const __returned__ = { route, FacilityId, initialValues, get TenantModule() { return TenantModule }, get TenantFacilityModule() { return TenantFacilityModule }, TenentDetailCRUD, get MetadataModule() { return MetadataModule }, computed, onMounted, onUnmounted, get useRoute() { return useRoute } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})