import { defineComponent as _defineComponent } from 'vue'
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import {
  IFacilityCreate,
  IFacilityFileDTO,
  IFacilityUpdate,
} from "@/models/Facility";
import AccountModule from "@/store/modules/Account";
import TenantFacilityModule from "@ems/containers/Tenant/TenantFacility.module";
import MetadataModule from "@/store/modules/Metadata";
import { ElMessage } from "element-plus/lib/components/message";
import { Field, Form } from "vee-validate";
import { computed, onMounted, ref, toRefs, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { object, string } from "yup";
import { STATUS } from "@ems/constants/facility";
import { latRegExp, longRegExp } from "@ems/constants/location";
import { ICountries } from "@/models/Metadata";

interface RawFile {
  name: string;
  url: string;
}
interface Props {
  data?: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TenentDetailCRUD.Form',
  props: {
    data: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const uploadRef = ref<any>();
const props = __props;
const { data } = toRefs(props);
const schema = object({
  Name: string().required().label("Name"),
  CountryContinentId: string().required().label("Country"),
});

const route = useRoute();
const router = useRouter();
const { TenantId, facility_id: FacilityId } = route.params;
const allCountries = computed(() => MetadataModule.allCountriesGetter);
const fileImage = ref<any>();
const onSubmit = async (values: any, actions: any) => {
  if (!latRegExp.test(values.Latitude)) {
    actions.setFieldError(
      "Latitude",
      "Invalid geo location. Ex: 10.634205,106.464482"
    );
    return false;
  }

  if (!longRegExp.test(values.Longitude)) {
    actions.setFieldError(
      "Longitude",
      "Invalid geo location. Ex: 10.634205,106.464482"
    );
    return false;
  }
  if (FacilityId) {
    const dataSubmit = { ...values, FacilityId };
    await TenantFacilityModule.updateFacility(dataSubmit as IFacilityUpdate);
    if (fileImage.value) {
      createOrUpdateImageFacility(FacilityId as any, data.value.FileId);
    }
  } else {
    const dataSubmit = { ...values };
    const facilityData = await TenantFacilityModule.createFacility(
      dataSubmit as IFacilityCreate
    );
    if (!TenantFacilityModule.hasCreateErrorMsg) {
      await createOrUpdateImageFacility(facilityData.Id);
      actions.resetForm();
      router.push(`/facilities-monitoring`);
    }
    return false;
  }
};

const createOrUpdateImageFacility = async (
  facilityId: string,
  fileId?: string
) => {
  const dataUploadFile = parseDataRequestFile(facilityId as any, fileId);
  if (fileId) {
    await TenantFacilityModule.updateImageFacility(dataUploadFile as any);
    return;
  }
  await TenantFacilityModule.createImageFacility(
    dataUploadFile as IFacilityFileDTO
  );
};
const parseDataRequestFile = (FacilityId: string, FileId?: string) => {
  const formData = new FormData();
  formData.append("file", fileImage.value);
  const data = {
    File: formData,
    FacilityId: FacilityId,
    FileId: FileId,
  };
  return data;
};
const handleUploadImageSuccess = (
  res: any,
  file: any,
  field: any,
  setFieldValue: any
) => {
  setFieldValue(field.name, URL.createObjectURL(file.raw));
};

const onChangeFile = (file: any, res: any, setFieldValue: any) => {
  setFieldValue("ImageUrl", URL.createObjectURL(file.raw));
  fileImage.value = file.raw;
};
const beforeUploadImage = (file: any) => {
  const isJPG = file.type === "image/jpeg" || file.type === "image/png";
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isJPG) {
    ElMessage.error("Avatar picture must be JPG format!");
  }
  if (!isLt2M) {
    ElMessage.error("Avatar picture size can not exceed 2MB!");
  }
  return isJPG && isLt2M;
};
const handleUploadFileSuccess = (file: any, res: any, field: any) => {
  console.log(URL.createObjectURL(file.raw));
};
const beforeUploadFile = (file: any) => {
  const isJPG = file.type === "image/jpeg";
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isJPG) {
    ElMessage.error("Avatar picture must be JPG format!");
  }
  if (!isLt2M) {
    ElMessage.error("Avatar picture size can not exceed 2MB!");
  }
  return isJPG && isLt2M;
};
const options = ref<ICountries[]>([]);
const remoteMethod = (query: string) => {
  if (query) {
    setTimeout(() => {
      options.value = allCountries.value.filter((item) => {
        return item.Name.toLowerCase().includes(query.toLowerCase());
      });
    }, 200);
  } else if (query === "") {
    options.value = allCountries.value;
  } else {
    options.value = [];
  }
};
watch(allCountries, () => {
  filterOptionCountry();
});
onMounted(() => {
  filterOptionCountry();
});
const filterOptionCountry = () => {
  options.value = allCountries.value.map((item) => {
    return { Value: `${item.Value}`, Name: `${item.Name}` };
  });
};
const Authorization = computed(
  () => AccountModule.getUserInfo?.authorizationString + ""
);
const fileListPPA = ref<RawFile[]>([
  {
    name: "Document 1.pdf",
    url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
  },
]);

const __returned__ = { uploadRef, props, data, schema, route, router, TenantId, FacilityId, allCountries, fileImage, onSubmit, createOrUpdateImageFacility, parseDataRequestFile, handleUploadImageSuccess, onChangeFile, beforeUploadImage, handleUploadFileSuccess, beforeUploadFile, options, remoteMethod, filterOptionCountry, Authorization, fileListPPA, BaseSvgIcon, get IFacilityCreate() { return IFacilityCreate }, get IFacilityFileDTO() { return IFacilityFileDTO }, get IFacilityUpdate() { return IFacilityUpdate }, get AccountModule() { return AccountModule }, get TenantFacilityModule() { return TenantFacilityModule }, get MetadataModule() { return MetadataModule }, get ElMessage() { return ElMessage }, get Field() { return Field }, get Form() { return Form }, computed, onMounted, ref, toRefs, watch, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get object() { return object }, get string() { return string }, get STATUS() { return STATUS }, get latRegExp() { return latRegExp }, get longRegExp() { return longRegExp }, get ICountries() { return ICountries } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})